import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const Assement = () => {
    const {state} = useLocation()
    const navigate = useNavigate()

    useEffect(() => {

        const handleBackButton = () => {
            navigate("/")
        }

        window.addEventListener('popstate', handleBackButton);

    }, [window.addEventListener])

    return (
        <section className="quiz-wrapper">
            <div className="container">
                <div className="col-12 col-md-10 col-lg-8 m-auto">
                <div className="quiz-box p-4">
                    <h3 className="quiz-name text-center mb-3">Your assessment</h3>
                
                    <div className="assessment-box">
                    <div className="card">
                        <h4 className="card-title"><span>{`${state?.title?.firstTitle} ${state?.title?.secondTitle}`}</span></h4>
                        <div className="card-body">
                            {state?.description?.map((list, index) => {
                                return <p key={index}>{list?.details}</p>
                            })}
                        {/* <p>For a personalised, in-depth assessment, we advise you to book a specialist consultation with one of our TRT experts. As part of your consultation, you will also be informed about the most suitable treatment options to improve your health and make you feel like yourself again.</p>
                        <p>Please book your consultation by clicking on the link below.</p> */}
                        </div>
                    </div>
                    </div>

                    <div className="nxt-btn text-center mt-4">
                        <a href="https://on.clinicsoftware.com/harleystreettrt/online-booking" target="_blank" className="btn btn-primary" role="button">Book your consultation</a>
                        <a href="https://www.harleystreettrt.co.uk/about-trt/" target="_blank" className="btn btn-primary ms-3" role="button">Learn about testosterone</a>
                    </div>
                </div>
                </div>
            </div>
        </section>
    )
}

export default Assement