import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

const assessmentResult = [{
    title: {
        firstTitle:"Low",
        secondTitle:"testosterone",
    },
    description:[
        {details:"Your responses indicate that you are likely to be low on testosterone."},
        {details:"For a personalised, in-depth assessment, we advise you to book a specialist consultation with one of our TRT experts. As part of your consultation, you will also be informed about the most suitable treatment options to improve your health and make you feel like yourself again."},
        {details:"Please book your consultation by clicking on the link below."}
    ]
},
{
    title: {
        firstTitle:"Inconclusive",
        secondTitle:"results",
    },
    description:[
        {details:"Based on your responses, there wasn't enough information to rule out low testosterone."},
        {details:"We advise you to book a specialist consultation with one of our TRT experts for an in-depth assessment. You can do this by clicking on the link below."}
    ]
},
{
    title: {
        firstTitle:"Unlikely to be low on",
        secondTitle:"testosterone",
    },
    description:[
        {details:"Your responses indicate that you are unlikely to be low on testosterone. However, we recommend checking with a specialist to ensure the results are correct."},
        {details:"We advise you to book a specialist consultation for an in-depth assessment. You can do this by clicking on the link below."}
    ]
}
]

const Quize = () => {

    const [currentQuestion, setCurrentQuestion] = useState(1)
    const navigate = useNavigate()
    const [ansValidation, setAnsValidation] = useState("")

    const [questionList, setQuestionList] = useState([{
      answer:"",
      no:1,
      question:"Do you have a decrease in libido (sex drive)?"
    },{
      answer:"",
      no:2,
      question:"Do you have a lack of energy?"
    },{
      answer:"",
      no:3,
      question:"Do you have a decrease in strength and/or endurance?"
    },{
      answer:"",
      no:4,
      question:"Have you lost height?"
    },{
      answer:"",
      no:5,
      question:"Have you noticed a decreased ‘enjoyment of life’?"
    },{
      answer:"",
      no:6,
      question:"Are you sad and/or grumpy?"
    },{
      answer:"",
      no:7,
      question:"Are your erections less strong?"
    },{
      answer:"",
      no:8,
      question:"Have you noted a recent deterioration in your ability to play sports?"
    },{
      answer:"",
      no:9,
      question:"Are you falling asleep after dinner?"
    },{
      answer:"",
      no:10,
      question:"Has there been a recent deterioration in your work performance?"
    },])
  
    const onAnswerChangeHandler = useCallback((e, no, yesOrNoItems) => {
      if (yesOrNoItems === "yes") {
        setQuestionList((prev) => {
            const latestQuestionList = [...prev]
    
            const currentAnswerIndex = latestQuestionList?.findIndex((list) => {
              return list.no === no
            })
            
            latestQuestionList[currentAnswerIndex] = {
              ...latestQuestionList[currentAnswerIndex],
              answer:yesOrNoItems
            }
  
            return latestQuestionList
        })  
        setAnsValidation("")
      } else if (yesOrNoItems === "no") {
        setQuestionList((prev) => {
          const latestQuestionList = [...prev]
  
          const currentAnswerIndex = latestQuestionList?.findIndex((list) => {
            return list.no === no
          })
          
          latestQuestionList[currentAnswerIndex] = {
            ...latestQuestionList[currentAnswerIndex],
            answer:yesOrNoItems
          }
  
          return latestQuestionList
      })  
      setAnsValidation("")
      }
    }, [])
  
    const onContinueAndBackHandler = useCallback((ans) => {
        if (ans) {
            setCurrentQuestion((prev) => {
                if (prev < questionList.length) {
                    return prev + 1
                } else {

                    const selectedYesInOneAndSevenArr = questionList?.filter((list) => {
                        if (list.no === 1 || list.no === 7) {

                            return list.answer === "yes" 
                        }
                    })

                    const selectedOneOrSeven = selectedYesInOneAndSevenArr.length === 1 || selectedYesInOneAndSevenArr.length === 2
                    

                    const selectedAllNo = questionList?.every((items) => {
                        return items.answer === "no"
                    })

                    const removeOneAndSeven = questionList.filter((items) => {
                        return items.no !== 1 && items.no !== 7
                    })

                    const checkOneOrTwoYes = removeOneAndSeven?.filter((list) => {
                        return list.answer === "yes"
                    })

                    const selectedYesNotIncludingOneAndSeven = checkOneOrTwoYes.length === 1 || checkOneOrTwoYes.length === 2

                    // console.log(selectedYesNotIncludingOneAndSeven)

                    if (!!selectedYesNotIncludingOneAndSeven) {
                        navigate("/assessment", {state:assessmentResult[1]})
                    } else if (!!selectedAllNo) {
                        navigate("/assessment",  {state:assessmentResult[2]})
                    } else if (!!selectedOneOrSeven || checkOneOrTwoYes.length >= 3) {
                        navigate("/assessment", {state:assessmentResult[0]})
                    }
                }
            })
            setAnsValidation("")
        } else {
            setAnsValidation("Please select any one option")
        }
    }, [questionList])

    return (
        <>
              {/* {questionList?.map((items) => {
        if (items.no === currentQuestion) {
          return (
            <>
              <h1>{`${items.no} ${items.question}`}</h1>
              <label>
                Yes
                <input type='radio' value="yes" checked={items.answer === "yes"} onChange={(e) => onAnswerChangeHandler(e, items.no, "yes")}/>
              </label>
              <label>
                No
                <input type='radio' value="no" checked={items.answer === "no"} onChange={(e) => onAnswerChangeHandler(e, items.no, "no")}/>
              </label>
              {items.answer === "yes" || items.answer === "no" ? <button onClick={onContinueAndBackHandler}>continue</button> : ""}
            </>
          )
        }
      })} */}
        <section className="quiz-wrapper">
            <div className="container">
                <div className="col-12 col-md-10 col-lg-8 m-auto">
                <div className="quiz-box p-4">
                    {questionList?.map((items, index) => {
                        if (items.no === currentQuestion) {
                            return (
                                <div key={index}>
                                    <h3 className="quiz-name text-center mb-3">{items?.question}</h3>
                                    <div className="quiz-chk-box">
                                    <div className="form-check" onClick={(e) => onAnswerChangeHandler(e, items.no, "yes")}>
                                        <input className="form-check-input" value="yes" checked={items.answer === "yes"} onChange={(e) => onAnswerChangeHandler(e, items.no, "yes")} type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                        Yes
                                        </label>
                                    </div>
                                    <div className="form-check" onClick={(e) => onAnswerChangeHandler(e, items.no, "no")}>
                                        <input className="form-check-input" value="no" checked={items.answer === "no"} onChange={(e) => onAnswerChangeHandler(e, items.no, "no")} type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                        No
                                        </label>
                                    </div>
                                    {ansValidation && <p className="text-danger">{ansValidation}</p>}
                                    </div>
                                    <div className="nxt-btn text-center mt-4">
                                        <button href="#" onClick={() => onContinueAndBackHandler(items.answer)} className="btn btn-primary" role="button">Continue</button>
                                    </div>
                                </div>
                            )
                        }
                    })}
                </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Quize