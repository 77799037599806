import './App.css';
import { useCallback, useState } from 'react';

import "../src/css/animate.min.css"
import "../src/css/bootstrap.min.css"
import "../src/css/style.css"
import "../src/css/responsive.css"

import MainLayout from './component/MainLayout';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Quize from './component/Quize';
import Assement from './component/Assement';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<MainLayout/>}>
          <Route path='/' element={<Quize/>}/>
        </Route>
        <Route path='/assessment' element={<MainLayout/>}>
          <Route path='/assessment' element={<Assement/>}/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
