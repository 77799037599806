import { useEffect } from "react"
import Header from "./Header"
import Quize from "./Quize"
import Assement from "./Assement"
import { Outlet } from "react-router-dom"

const MainLayout = () => {

    return(
        <>
            <Header/>
            <Outlet/>
        </>
    )
}

export default MainLayout