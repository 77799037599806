import { Link } from "react-router-dom"
import logo from "../images/logo.svg"
const Header = () => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand" target="_blank" to="https://www.harleystreettrt.co.uk/">
                        <img src={logo} alt="Main Logo" className="img-fluid" width="200"/>
                    </Link>
                </div>
            </nav>
        </header>
    )
}

export default Header